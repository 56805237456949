import { modalTypes } from '@/components/ModalWrapper';

export const localStorageKeys = {
    LOGGED_IN_USER: 'loggedInUser',
    SELECTED_COUNTRY: 'selectedCountry',
    SELECTED_ORDER_TYPE: 'selectedOrderType',
    ACCOUNT_DATA: 'accountData',
    ACCOUNT_CONFIG: 'accountConfig',
    DEVICE_LOCATION: 'deviceLocation',
    DEFAULT_ADDRESS: 'defaultAddress',
    SELECTED_ADDRESS: 'selectedAddress',
    AUTH_DATA: 'authData',
    COUPON: 'coupon',
};

export const modalTypeKey = 'modalType';

export const ORDER_TYPES = {
    DELIVERY: 'delivery',
    TAKE_AWAY: 'takeaway',
};

export const addressTypes = {
    SAVED_ADDRESS: 'saved_address',
    TEMPERORARY_ADDRESS: 'temporary_address',
    CUSTOM_ADDRESS: 'custom_address',
};

export const addressSaveTypes = {
    TEMPORARY: 'Temporary',
    NEW_ADDRESS: 'NEW_ADDRESS',
};

export const ROUTES = {
    public: ['/auth/signin', '/home', '/categories'],
    modals: [...Object.keys(modalTypes)],
};

export const ORDER_STATUS = {
    PLACED: 'PLACED',
    ACCEPTED: 'ACCEPTED',
    PREPARING: 'PREPARING',
    TRANSIT: 'TRANSIT',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
};

export const OfferTypes = {
    PERCENTAGE: 'PERCENTAGE',
    FLAT: 'FLAT',
    FREE_DELIVERY: 'FREE_DELIVERY',
};

export const PromoStatus = {
    ACTIVE: 'ACTIVE',
};

export const Operation = {
    increment: 'increment',
};

export const TaxType = {
    CGST: 'CGST',
    SGST: 'SGST',
    VAT: 'VAT',
};

export const CouponType = {
    FLAT: 'FLAT',
    PERCENTAGE: 'PERCENTAGE',
    FREE_DELIVERY: 'FREE_DELIVERY',
};

export const PRODUCT_TYPES = {
    VEG: 'Veg',
    NON_VEG: 'Non Veg',
};
export const API_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL;

export const MOD_GROUP_TYPES = {
    VARIANT: 'variant',
    ADDON: 'addon',
};
