import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { updateCurrentAccount } from '@/redux/slices/account';
import { updateAuthData } from '@/redux/slices/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useReverseGeocoding from './useReverseGeocoding';
const useDeviceLocation = () => {
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const {
    fetchAddresses
  } = useReverseGeocoding();
  const dispatch = useDispatch();
  const updateLocationStatus = useCallback(async (status, message, locationData = {}) => {
    let coordinates = {};
    if (locationData.data) {
      coordinates = {
        lat: locationData.data.latitude,
        lng: locationData.data.longitude
      };
    }
    const latlng = Object.values(coordinates).join(',');
    if (latlng) {
      const addresses = await fetchAddresses([Object.values(coordinates).join(',')]);
      const deviceLocation = {
        status,
        message,
        address: addresses?.[0],
        isLocationAllowed: locationData.isLocationAllowed,
        data: {
          ...coordinates
        }
      };
      actions.updateNestedValue(localStorageKeys.DEVICE_LOCATION, deviceLocation);
      dispatch(updateCurrentAccount({
        deviceLocation
      }));
      dispatch(updateAuthData({
        isDeviceLocationAllowed: true
      }));
    }
  }, [actions]);
  const handleSuccess = useCallback(position => {
    const {
      latitude,
      longitude
    } = position.coords;
    updateLocationStatus(true, 'Location retrieved successfully', {
      isLocationAllowed: true,
      data: {
        latitude,
        longitude
      }
    });
    return {
      status: true,
      message: 'Success',
      data: {
        latitude,
        longitude
      }
    };
  }, [updateLocationStatus]);
  const getLocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      const userConfirmation = window.confirm('We need your location to provide better service. Do you allow us to access your current location?');
      if (!userConfirmation) {
        const message = 'Location access denied by user';
        updateLocationStatus(false, message, {
          isLocationAllowed: false
        });
        reject(new Error(message));
        return;
      }
      if (!navigator.geolocation) {
        const message = 'Geolocation is not supported by your browser';
        console.warn(message);
        updateLocationStatus(false, message, {
          isLocationAllowed: false
        });
        reject(new Error(message));
      } else {
        navigator.geolocation.getCurrentPosition(position => resolve(handleSuccess(position)), error => {
          console.error('Geolocation error:', error);
          reject(new Error(`Geolocation error: ${error.message}`));
        }, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        });
      }
    });
  }, [handleSuccess]);
  return {
    getLocation
  };
};
export default useDeviceLocation;