import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { subYears } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { updateUser } from '@/service/user.service';
import { saveDateFormat, viewDateFormat } from '@/lib/helpers/common';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '@/redux/slices/auth';
const genderOptions = [{
  value: 'MALE',
  label: 'male'
}, {
  value: 'FEMALE',
  label: 'female'
}, {
  value: 'OTHER',
  label: 'other'
}];
const validationSchema = t => Yup.object().shape({
  firstName: Yup.string().required(t('firstNameError')),
  lastName: Yup.string().required(t('lastNameError'))
  //  dob: Yup.string().required(t('dobError')),
  // gender: Yup.string().required(t('genderError')),
  // email: Yup.string().email(t('invalidEmail')).required(t('emailError')),
});
const EditProfileForm = ({
  editEnabled,
  setEditEnabled
}) => {
  const t = useTranslations('profile');
  const profileData = useSelector(store => store.auth)?.auth;
  const [selectedDate, setSelectedDate] = useState(profileData?.dob ? new Date(profileData.dob) : null);
  const dispatch = useDispatch();
  const initialValues = {
    firstName: profileData?.firstName || '',
    lastName: profileData?.lastName || '',
    dob: viewDateFormat(profileData?.dob) || null,
    gender: profileData?.gender || null,
    email: profileData?.email || null
  };
  const formattedValues = values => {
    const result = {};
    Object.keys(values).forEach(key => {
      if (values[key] !== null && values[key] !== '') {
        result[key] = values[key];
      }
    });
    if (selectedDate) {
      result.dob = saveDateFormat(selectedDate);
    }
    return result;
  };
  const submitHandler = async (values, setSubmitting) => {
    try {
      const response = await updateUser(formattedValues(values));
      if (response.code !== 200) {
        throw new Error(response.message);
      }
      toast.success(t('successMessage'));
      const updatedAuthData = {
        ...profileData,
        ...response.data
      };
      setEditEnabled(false);
      dispatch(setAuthData(updatedAuthData));
    } catch (error) {
      console.error('error', error);
      toast.error(t('errorMessage'));
    } finally {
      setSubmitting(false);
    }
  };
  return <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={(values, {
    setSubmitting
  }) => {
    submitHandler(values, setSubmitting);
  }} data-sentry-element="Formik" data-sentry-component="EditProfileForm" data-sentry-source-file="editProfileForm.jsx">
            {({
      isSubmitting,
      setFieldValue
    }) => <Form className={`settingsSection ${editEnabled ? '' : 'disabledField'}`}>
                    <div className="rowGroup">
                        <div className="formGroup">
                            <label htmlFor="firstName">*{t('firstName')}</label>
                            <Field type="text" name="firstName" className="formControl" placeholder={t('enter_First_Name')} maxLength="16" />
                            <ErrorMessage name="firstName" component="div" className="error-message" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="lastName">*{t('lastName')}</label>
                            <Field type="text" name="lastName" className="formControl" placeholder={t('enter_Last_Name')} maxLength="16" />
                            <ErrorMessage name="lastName" component="div" className="error-message" />
                        </div>
                    </div>
                    <div className="rowGroup">
                        <div className="formGroup">
                            <label htmlFor="dateOfBirth">{t('dateOfBirth')}</label>
                            <DatePicker selected={selectedDate} onChange={date => {
            setSelectedDate(date);
            setFieldValue('dob', viewDateFormat(date));
          }} placeholderText={t('dd_mm_yyyy')} dateFormat="dd MMMM yyyy" className="formControl" maxDate={subYears(new Date(), 12)} minDate={subYears(new Date(), 99)} showYearDropdown dropdownMode="select" scrollableYearDropdown yearDropdownItemNumber={100} />
                            <ErrorMessage name="dob" component="div" className="error-message" />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="gender">{t('gender')}</label>
                            <Field name="gender" component="select" className="formControl">
                                <option value="" selected={profileData?.gender === null}>
                                    {t('selectGender')}
                                </option>
                                {genderOptions.map(({
              value,
              label
            }) => <option key={value} value={value} selected={value === profileData?.gender}>
                                        {t(label)}
                                    </option>)}
                            </Field>
                            <ErrorMessage name="gender" component="div" className="error-message" />
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="email">{t('email')}</label>
                        <Field type="text" name="email" className="formControl" placeholder={t('enter_Email')} />
                        <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                    <div className="btn-group">
                        <button className="close-btn" onClick={() => setEditEnabled(false)}>
                            {t('cancel')}
                        </button>
                        <button type="submit" disabled={isSubmitting} className="primary-btn">
                            {t('save')}
                        </button>
                    </div>
                </Form>}
        </Formik>;
};
EditProfileForm.propTypes = {
  editEnabled: PropTypes.bool,
  setEditEnabled: PropTypes.func
};
export default EditProfileForm;