import { localStorageKeys, modalTypeKey } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { getDocument } from '@/lib/firebase/query';
import { setAccount } from '@/redux/slices/account';
import { setAuthData } from '@/redux/slices/auth';
import { addHomeData } from '@/redux/slices/common';
import { updateCurrentOrder } from '@/redux/slices/order';
import { setNearByStores, setNearestStore } from '@/redux/slices/stores';
import { getHomeData, getNearByStores, getNearestStore } from '@/service/home.service';
import { isFalsy } from '@/utils';
import { collections } from '@/utils/constants/fb.collections';
import { useRouter } from 'next/router';
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../footer/Footer';
import Header from '../header/header';
import { modalTypes } from '../ModalWrapper';
const MainLayout = ({
  children
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isAuthRoute = router.route.includes('/auth');
  const {
    isReady,
    updateParam
  } = useUrlParams();
  const {
    actions,
    storedValue
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  useEffect(() => {
    if (isReady && router.query.modalType !== modalTypes.productDetails) {
      if (!actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY)) {
        updateParam(modalTypeKey, modalTypes.selectCountry, {
          shallow: false
        });
      } else if (!actions.getNestedValue(localStorageKeys.SELECTED_ORDER_TYPE)) {
        updateParam(modalTypeKey, modalTypes.selectOrderType, {
          shallow: false
        });
      }
    }
  }, [router.asPath, storedValue, isReady, actions]);
  useEffect(() => {
    if (!isFalsy(accountData) || !isFalsy(orderData)) {
      fetchHomeData(accountData?.selectedCountry?.id);
    }
    const selectedLocation = getSelectedLocation();
    if (!isFalsy(selectedLocation)) {
      getNearestStoreData(selectedLocation);
      getNearByStoresData(selectedLocation);
    }
  }, [accountData, orderData]);
  const getSelectedLocation = () => {
    let location = {};
    if (accountData?.deviceLocation?.data) {
      location = accountData?.deviceLocation?.data;
    }
    if (orderData?.address?.geoData?.coordinates) {
      location = orderData?.address?.geoData?.coordinates;
    }
    return location;
  };
  const getNearByStoresData = async (selectedLocation = {}) => {
    if (Object.keys(selectedLocation || {}).length) {
      try {
        const response = await getNearByStores(selectedLocation);
        dispatch(setNearByStores(response?.data));
        return response;
      } catch (error) {
        console.warn('error', error);
      }
    }
  };
  const getNearestStoreData = async (selectedLocation = {}) => {
    if (Object.keys(selectedLocation || {}).length) {
      try {
        const response = await getNearestStore(selectedLocation);
        dispatch(setNearestStore(response?.data));
        return response;
      } catch (error) {
        console.warn('error', error);
      }
    }
  };
  const fetchHomeData = async country => {
    if (!country) return;
    const {
      home,
      companyDetails
    } = await getHomeData(country, orderData);
    if (home) {
      dispatch(addHomeData({
        home,
        companyDetails
      }));
    }
  };
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const account = localStorage.getItem(localStorageKeys.ACCOUNT_DATA);
    const selectedCountry = account ? JSON.parse(account)[localStorageKeys.SELECTED_COUNTRY] : {};
    const deviceLocation = account ? JSON.parse(account)[localStorageKeys.DEVICE_LOCATION] : {};
    const selectedAddress = account ? JSON.parse(account)[localStorageKeys.SELECTED_ADDRESS] : {};
    dispatch(setAccount({
      deviceLocation,
      selectedCountry
    }));
    dispatch(updateCurrentOrder(selectedAddress));
    getCurrentUser({
      isDeviceLocationAllowed: !!deviceLocation?.isLocationAllowed
    });
  }, [dispatch, router.asPath]);
  const getCurrentUser = async ({
    isDeviceLocationAllowed
  }) => {
    const formatTimestamp = timestamp => new Date(timestamp.seconds * 1000).toISOString();
    const loggedInUserData = localStorage.getItem(localStorageKeys.LOGGED_IN_USER) || '{}';
    const parsedUserData = JSON.parse(loggedInUserData);
    if (!parsedUserData.uid) {
      dispatch(setAuthData({
        isDeviceLocationAllowed,
        isUserLoggedIn: false
      }));
      return;
    }
    try {
      const response = await getDocument(collections.customers, parsedUserData.uid);
      if (response) {
        const authData = {
          ...response,
          isDeviceLocationAllowed,
          isUserLoggedIn: true,
          createdAt: formatTimestamp(response.createdAt),
          updatedAt: formatTimestamp(response.updatedAt)
        };
        dispatch(setAuthData(authData));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  return <>
            {!isAuthRoute && <Header />}
            {children}
            {!isAuthRoute && <Footer />}
        </>;
};
export default MainLayout;
MainLayout.propTypes = {
  children: PropTypes.element
};