import { format } from 'date-fns';

/**
 * Format date for viewing in the app
 * @param {Date} date Date object
 * @returns {string} Formatted date string
 */
export const viewDateFormat = (date) => {
    return date ? format(new Date(date), 'dd MMMM yyyy') : '';
};

/**
 * Format date for saving to the database
 * @param {Date} date Date object
 * @returns {string} Formatted date string
 */
export const saveDateFormat = (date) => {
    return date ? format(new Date(date), 'yyyy-MM-dd') : '';
};

export const dateToTimeFormat = (date) => {
    return date ? format(new Date(date), 'hh:mm a') : '';
};

export const getUserFirstAndLastLetter = (firstName, lastName) => {
    const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
    return [firstLetter, lastLetter];
};

export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};
