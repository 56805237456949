// src/redux/slices/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: null,
        foodLines: [],
    },
    reducers: {
        setCartData: (state, action) => {
            state.cart = action.payload;
        },
        setFoodLines: (state, action) => {
            state.foodLines = action.payload;
        },
    },
});

export const { setCartData, setFoodLines } = cartSlice.actions;
export default cartSlice.reducer;
