import React from 'react';

function CoinIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path
                d="M10.8282 4.64845C9.43756 4.6461 7.69537 5.08556 5.97662 5.98009C4.2606 6.87462 2.90045 8.04649 2.10201 9.19102C1.30318 10.3316 1.08716 11.3942 1.48013 12.152C1.87349 12.9059 2.86802 13.3395 4.26216 13.3395C5.65631 13.3434 7.39849 12.902 9.11334 12.0113C10.8321 11.1168 12.1915 9.94102 12.9883 8.8004C13.7891 7.65977 14.0001 6.59727 13.6094 5.83946C13.2149 5.08556 12.2227 4.64845 10.8282 4.64845ZM14.418 7.39415C14.2813 7.99181 13.9844 8.60509 13.5665 9.20274C12.6836 10.4645 11.2422 11.6949 9.43756 12.6324C7.63287 13.5738 5.80084 14.0465 4.26177 14.0465C3.53521 14.0465 2.86177 13.9371 2.2942 13.7066L2.70787 14.5035C3.10162 15.2613 4.09341 15.6949 5.48834 15.6949C6.88287 15.6949 8.62506 15.2574 10.3399 14.3629C12.0586 13.4723 13.418 12.2965 14.2149 11.152C15.0118 10.0113 15.2305 8.94884 14.836 8.19493L14.418 7.39415ZM15.543 8.0504C15.9805 9.09337 15.629 10.3629 14.793 11.5543C14.0547 12.6129 12.9258 13.6402 11.5274 14.4996C11.961 14.5465 12.4141 14.5699 12.8751 14.5699C14.8086 14.5699 16.5587 14.1559 17.793 13.5113C19.0313 12.8668 19.711 12.0231 19.711 11.1715C19.711 10.3199 19.0313 9.47618 17.793 8.83165C17.1641 8.50353 16.3985 8.23399 15.543 8.0504ZM19.711 12.9254C19.3126 13.3902 18.7657 13.8004 18.1211 14.1363C16.754 14.8473 14.9102 15.2731 12.8751 15.2731C12.0469 15.2731 11.2501 15.2027 10.504 15.0699C9.59771 15.527 8.68756 15.8668 7.81256 16.0895C7.85943 16.1168 7.90631 16.1402 7.95709 16.1676C9.19146 16.8121 10.9415 17.2262 12.8751 17.2262C14.8086 17.2262 16.5587 16.8121 17.793 16.1676C19.0313 15.5231 19.711 14.6793 19.711 13.8277V12.9254Z"
                fill="#F39200"
            />
        </svg>
    );
}

export default CoinIcon;
