import CloseIcon from '@/components/icons/CloseIcon';
import PropTypes from 'prop-types';
import ChevronRight from '@/components/icons/chevronRight';
const ProfileHeader = ({
  setShowProfile,
  showProfile,
  backToProfile,
  title
}) => {
  return <div className="sheetHeader" data-sentry-component="ProfileHeader" data-sentry-source-file="profileHeader.jsx">
            <div className="back-icon">
                {!showProfile && <button className="back-btn" onClick={backToProfile}>
                        <ChevronRight />
                    </button>}
                <h4>{title}</h4>
            </div>
            <button className="closeBtn" onClick={() => setShowProfile(false)}>
                <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="profileHeader.jsx" />
            </button>
        </div>;
};
ProfileHeader.propTypes = {
  setShowProfile: PropTypes.func.isRequired,
  showProfile: PropTypes.bool.isRequired,
  backToProfile: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
export default ProfileHeader;