import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useAuth } from '@/hooks/useAuth';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Location from '../icons/Location';
const settings = {
  dots: false,
  // infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [{
    breakpoint: 991,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 2
    }
  }, {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  }]
};
function Outlets() {
  const t = useTranslations('Home');
  const {
    nearByStores = []
  } = useSelector(store => store.store);
  const {
    getLocaleContent
  } = useAuth();
  if (!nearByStores.length) return;
  return <>
            <h4 className="">
                <small>{t('nearby')}</small> {t('outlets')}
            </h4>
            <p className="pb-50">Lorem Ipsum is simply dummy text of the printing</p>
            <Slider {...settings} data-sentry-element="Slider" data-sentry-source-file="Outlets.jsx">
                {nearByStores?.map(item => <div className="outlets-items" key={item.id}>
                        <figure>
                            <img src={item.photo} alt="" />
                            <figcaption>
                                <h6>{getLocaleContent(item.name)}</h6>

                                <div className="btn-group">
                                    <button className="btn">order now</button>
                                    <button className="btn-icon">
                                        <Location />
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                    </div>)}
            </Slider>
        </>;
}
Outlets.propTypes = {
  locale: PropTypes.string
};
export default Outlets;