import React from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import PropTypes from 'prop-types';
import clientSays from '../../../public/images/client-says-img.png';
import TestimonialCarousel from './TestimonialCarousel';
function Testimonial({
  locale = 'en'
}) {
  const {
    home
  } = useSelector(store => store.common);
  return <div className="testimonial-wraper" data-sentry-component="Testimonial" data-sentry-source-file="Testimonial.jsx">
            <div className="container">
                <div className="client-says-wrap">
                    <div className="client-says-img">
                        <Image src={clientSays} alt="" data-sentry-element="Image" data-sentry-source-file="Testimonial.jsx" />
                    </div>
                    <TestimonialCarousel data={home?.TESTIMONIALS} locale={locale} data-sentry-element="TestimonialCarousel" data-sentry-source-file="Testimonial.jsx" />
                </div>
            </div>
        </div>;
}
Testimonial.propTypes = {
  locale: PropTypes.string
};
export default Testimonial;