import { useTranslations } from 'next-intl';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const addressTypes = ['home', 'work', 'friendsAndFamily', 'other'];
const AddressForm = ({
  currentAddress,
  handleFieldChange,
  errorsData,
  validateField
}) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    houseNumber: '',
    apartment: '',
    directions: '',
    addressType: 'other'
  });
  const t = useTranslations();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (currentAddress) {
      setFormData({
        ...currentAddress
      });
    }
  }, [currentAddress]);
  useEffect(() => {
    setErrors({
      ...errors,
      ...errorsData
    });
  }, [errorsData]);
  const handleChange = e => {
    const {
      name,
      value
    } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    const fieldStatus = validateField(name, value);
    setErrors({
      ...errors,
      ...fieldStatus.errors
    });
    handleFieldChange(name, value);
  };
  return <div className="location-map" data-sentry-component="AddressForm" data-sentry-source-file="AddressForm.jsx">
            <div className="form-block">
                <div className="rowGroup">
                    <div className="formGroup">
                        <label htmlFor="name">{t('profile.recieverNameLabel')}</label>
                        <input type="text" className="formControl" placeholder={t('profile.recieverNameLabel')} name="name" id="name" value={formData.name} onChange={handleChange} />

                        {errors.name && <span className="error-message">{t(`ErrorMessages.${errors.name}`)}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="houseNumber">{t('profile.houseFlatBlockNoLabel')}</label>
                        <input type="text" className="formControl" placeholder={t('profile.houseFlatBlockNoLabel')} name="houseNumber" id="houseNumber" value={formData.houseNumber} onChange={handleChange} />

                        {errors.houseNumber && <span className="error-message">{t(`ErrorMessages.${errors.houseNumber}`)}</span>}
                    </div>
                </div>
                <div className="rowGroup">
                    <div className="formGroup">
                        <label htmlFor="apartment">{t('profile.apartmentRoadAreaLabel')}</label>
                        <input type="text" id="apartment" name="apartment" className="formControl" placeholder={t('profile.apartmentRoadAreaLabel')} value={formData.apartment} onChange={handleChange} />
                        {errors.apartment && <span className="error-message">{t(`ErrorMessages.${errors.apartment}`)}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="phone-input">{t('profile.phoneNumberLabel')}</label>
                        <PhoneInput id="phone-input" defaultCountry="IN" placeholder={t('profile.phoneNumberLabel')} value={formData.phone} onChange={val => handleChange({
            target: {
              name: 'phone',
              value: val
            }
          })} data-sentry-element="PhoneInput" data-sentry-source-file="AddressForm.jsx" />
                        {errors.phone && <span className="error-message">{t(`ErrorMessages.${errors.phone}`)}</span>}
                    </div>
                </div>
                <div className="formGroup">
                    <label htmlFor="directions">{t('profile.directionsToReachLabel')}</label>
                    <input type="text" name="directions" id="directions" className="formControl" placeholder={t('profile.directionsToReachLabel')} value={formData.directions} onChange={handleChange} />
                    {errors.directions && <span className="error-message">{t(`ErrorMessages.${errors.directions}`)}</span>}
                </div>
                <div className="save-list-wrap">
                    <h4>{t('profile.saveAs')}</h4>
                    <div className="save-list">
                        {addressTypes.map(item => <button className={item === formData.addressType ? 'is-active' : ''} key={item} value={t('profile.' + item)} onClick={() => handleChange({
            target: {
              name: 'addressType',
              value: item
            }
          })}>
                                {t('profile.' + item)}
                            </button>)}
                        <div className="formGroup">
                            <input type="text" name="addressType" id="addressType" className="formControl" placeholder={t('profile.saveAs')} value={addressTypes.includes(formData.addressType) ? '' : formData.addressType} onChange={handleChange} />
                            {errors.addressType && <span className="error-message">{t(`ErrorMessages.${errors.addressType}`)}</span>}
                        </div>
                    </div>
                </div>
            </div>
            {/* {onLocationConfirm && (
                <button className="submint-btn" onClick={handleConfirm}>
                    {t('profile.confirmLocation')}
                </button>
             )} */}
        </div>;
};
export default AddressForm;
AddressForm.propTypes = {
  validateField: PropTypes.func,
  handleFieldChange: PropTypes.func,
  currentAddress: PropTypes.object,
  errorsData: PropTypes.object
};