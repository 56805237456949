import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    order: {},
};

const orderSlice = createSlice({
    name: 'Order',
    initialState,
    reducers: {
        updateCurrentOrder(state, action) {
            return {
                order: {
                    ...state.order,
                    ...action.payload,
                },
            };
        },
        setCurrentOrder(state, action) {
            state.order = action.payload;
        },
    },
});

export const { updateCurrentOrder, setCurrentOrder } = orderSlice.actions;
export default orderSlice.reducer;
