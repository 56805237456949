import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    account: {},
};

const accountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        updateCurrentAccount(state, action) {
            return {
                account: {
                    ...state.account,
                    ...action.payload,
                },
            };
        },
        setAccount(state, action) {
            state.account = action.payload;
        },
    },
});

export const { updateCurrentAccount, setAccount } = accountSlice.actions;
export default accountSlice.reducer;
