import { useTranslations } from 'next-intl';
export const Greetings = () => {
  const t = useTranslations('greetings');
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) {
    return t('morning');
  } else if (hour >= 12 && hour < 17) {
    return t('afternoon');
  } else if (hour >= 17 && hour < 21) {
    return t('evening');
  } else {
    return t('night');
  }
};