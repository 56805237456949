import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nearestStore: {},
    nearByStores: [],
};

const storeSlice = createSlice({
    name: 'Store',
    initialState,
    reducers: {
        setNearestStore(state, action) {
            state.nearestStore = action.payload;
        },
        setNearByStores(state, action) {
            state.nearByStores = action.payload;
        },
    },
});

export const { setNearestStore, setNearByStores } = storeSlice.actions;
export default storeSlice.reducer;
