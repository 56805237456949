import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Carticon from '../icons/Carticon';
import useCartFoodLines from '@/hooks/useCartData';
import useLocalStorage from '@/hooks/useLocalStorage';
const CartHeader = () => {
  const router = useRouter();
  const accountData = useSelector(store => store.account.account);
  const {
    storedValue
  } = useLocalStorage('loggedInUser', {});
  const customerId = storedValue?.uid;
  const {
    cart
  } = useCartFoodLines(customerId);
  const subtotal = cart?.itemTotal || 0;
  const itemCount = cart?.count || 0;
  const handleRedirect = () => {
    router.push('/cartitems');
  };
  return <div className="cartIconwrap" data-sentry-component="CartHeader" data-sentry-source-file="CartHeader.jsx">
            <div className="cartIcon">
                <button onClick={handleRedirect}>
                    <Carticon data-sentry-element="Carticon" data-sentry-source-file="CartHeader.jsx" />
                </button>
            </div>
            {subtotal !== 0 && <h4>
                    {accountData?.selectedCountry?.currency} {subtotal.toFixed(2)}{' '}
                    <small>{itemCount}x Items Added</small>
                </h4>}
        </div>;
};
export default CartHeader;