export const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?\d{7,15}$/;
    return phoneRegex.test(number);
};

export default async function loadMessages(locale) {
    return (await import(`@/lib/messages/langs/${locale}.json`)).default;
}

export const currencyFormatter = (code, amount) => {
    const currency = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        INR: '₹',
        UAE: 'AED',
    };
    return currency[code] ? `${currency[code]}${amount}` : `${amount} ${code}`;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
};

export const calculateBiteCoinAmount = (loyalty, companyData) => {
    return parseFloat(loyalty * (companyData?.biteCoinConfig?.coinValue / 100)) || 0;
};

export const generateHomeData = (homeData) => {
    const homeDataObject = homeData.reduce((obj, item) => {
        const key = item.type;
        if (!obj[key]) {
            obj[key] = [];
        }
        obj[key].push(item.data);
        return obj;
    }, {});

    const homeDataSorted = Object.keys(homeDataObject).reduce((obj, key) => {
        obj[key] = homeDataObject[key].sort((a, b) => a.sortOrder - b.sortOrder);
        return obj;
    }, {});

    return homeDataSorted;
};

export const isFalsy = (value) => {
    if (typeof value === 'function') {
        // Functions are always truthy
        return false;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    return !value;
};

export const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseCoordinates = (coordinates = {}) => {
    if (isFalsy(coordinates)) {
        return {};
    }
    const { lat, lng, longitude, latitude } = coordinates;
    return {
        lat: latitude || lat || null,
        lng: longitude || lng || null,
    };
};
