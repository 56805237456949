const Choosetheme = () => <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" data-sentry-element="svg" data-sentry-component="Choosetheme" data-sentry-source-file="Choosetheme.jsx">
        <g clipPath="url(#clip0_2726_11356)" data-sentry-element="g" data-sentry-source-file="Choosetheme.jsx">
            <path d="M8.49935 11.9137C10.3403 11.9137 11.8327 10.4214 11.8327 8.5804C11.8327 6.73945 10.3403 5.24707 8.49935 5.24707C6.6584 5.24707 5.16602 6.73945 5.16602 8.5804C5.16602 10.4214 6.6584 11.9137 8.49935 11.9137Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M8.5 1.24707V2.5804" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M8.5 14.5801V15.9134" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M3.3125 3.39355L4.25917 4.34022" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M12.7402 12.8203L13.6869 13.767" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M1.16602 8.58008H2.49935" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M14.5 8.58008H15.8333" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M3.3125 13.767L4.25917 12.8203" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
            <path d="M12.7402 4.34022L13.6869 3.39355" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Choosetheme.jsx" />
        </g>
        <defs data-sentry-element="defs" data-sentry-source-file="Choosetheme.jsx">
            <clipPath id="clip0_2726_11356" data-sentry-element="clipPath" data-sentry-source-file="Choosetheme.jsx">
                <rect width="16" height="16" fill="white" transform="translate(0.5 0.580078)" data-sentry-element="rect" data-sentry-source-file="Choosetheme.jsx" />
            </clipPath>
        </defs>
    </svg>;
export default Choosetheme;