import { localStorageKeys } from '@/configs/constants';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
export default function AuthGuard({
  children
}) {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    try {
      const loggedInUser = localStorage.getItem(localStorageKeys.LOGGED_IN_USER);
      const userData = loggedInUser ? JSON.parse(loggedInUser) : null;
      setIsAuthenticated(!!userData?.uid);
      if (!userData?.uid) {
        router.push(`/auth/signin`);
      }
    } catch (error) {
      router.push('/auth/signin');
    }
  }, [router]);
  if (isAuthenticated === null) {
    return null;
  }
  if (!isAuthenticated) {
    return null;
  }
  return children;
}
AuthGuard.propTypes = {
  children: PropTypes.node.isRequired
};