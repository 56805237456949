import { useEffect, useMemo, useState } from 'react';
const useLocalStorage = (key, initialValue = {}) => {
  const [storedValue, setStoredValue] = useState(getStorageValue(key, initialValue));
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    setIsInitialized(true);
  }, []);
  function getStorageValue(key, initialValue) {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue;
    }
  }
  const setValue = (key, value) => {
    try {
      const valueToStore = typeof value === 'function' ? value(storedValue[key] || {}) : value;
      const newStoredValue = {
        ...storedValue,
        [key]: valueToStore
      };
      setStoredValue(newStoredValue);
      window.localStorage.setItem(key, JSON.stringify(newStoredValue));
    } catch (error) {
      console.error('Error setting localStorage value:', error);
    }
  };
  const updateValue = (key, updater) => {
    const currentValue = getStorageValue(key) || {};
    setValue(key, () => updater ? updater(currentValue) : currentValue);
  };
  const removeValue = key => {
    try {
      const newStoredValue = {
        ...storedValue
      };
      delete newStoredValue[key];
      setStoredValue(newStoredValue);
      window.localStorage.setItem(key, JSON.stringify(newStoredValue));
    } catch (error) {
      console.error('Error removing localStorage value:', error);
    }
  };
  const updateNestedValue = (path, value) => {
    setStoredValue(() => {
      const pathArray = path.split('.');
      const currentValue = getStorageValue(key) || {};
      const newValue = JSON.parse(JSON.stringify(currentValue));
      let current = newValue;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const key = pathArray[i];
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      }
      current[pathArray[pathArray.length - 1]] = value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      return newValue;
    });
  };
  const getNestedValue = path => {
    const pathArray = path.split('.');
    let current = getStorageValue(key);
    for (const key of pathArray) {
      if (current === undefined || current === null) {
        return undefined;
      }
      current = current[key];
    }
    return current;
  };
  const removeKey = dataKey => {
    try {
      window.localStorage.removeItem(dataKey);
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  };
  const clearAllExcept = keepKeys => {
    try {
      // Parse the stored value for the given key
      const currentData = JSON.parse(window.localStorage.getItem(key)) || {};

      // Filter to retain only specified nested keys
      const newData = keepKeys.reduce((acc, path) => {
        const pathArray = path.split('.');
        let source = currentData;
        let target = acc;
        pathArray.forEach((key, index) => {
          if (source && source[key] !== undefined) {
            if (index === pathArray.length - 1) {
              target[key] = source[key];
            } else {
              target[key] = target[key] || {};
              source = source[key];
              target = target[key];
            }
          }
        });
        return acc;
      }, {});

      // Clear localStorage and reset only the retained data
      window.localStorage.clear();
      window.localStorage.setItem(key, JSON.stringify(newData));
      setStoredValue(newData); // Update storedValue to the new data
    } catch (error) {
      console.error('Error clearing localStorage except for certain keys:', error);
    }
  };
  const clearAll = () => {
    try {
      window.localStorage.clear();
    } catch (error) {
      console.error('Error clearing all localStorage data:', error);
    }
  };
  const actions = useMemo(() => ({
    setValue,
    updateValue,
    removeValue,
    updateNestedValue,
    getNestedValue,
    getStorageValue,
    removeKey,
    clearAll,
    clearAllExcept
  }), []);
  return {
    storedValue,
    actions,
    isInitialized
  };
};
export default useLocalStorage;