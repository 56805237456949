// cart/index.js
import { firestore } from '@/configs/firebase.config';
import cart from '@/utils/constants/cart';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    runTransaction,
    serverTimestamp,
    where,
} from 'firebase/firestore';
import { createProductDescription, simplifyProductModGroups } from './utility';
import { Operation, localStorageKeys } from '@/configs/constants';

/**
 * Adds or updates a product in the cart for a customer in Firestore.
 *
 * @param {Object} details - The product details object.
 * @param {Array} quantities - The array of quantities for each modifier group and its modifiers.
 * @param {Object} orderData - Information related to the order.
 * @param {Object} account - Account information including the selected country.
 * @returns {Object} - An object indicating success or failure.
 */
export const handleCart = async (cartData) => {
    try {
        const { details, quantities, orderData, account, authData, inAHurry, isMixed, remarks, storeData } = cartData;
        const customerId = authData?.uid;
        const cartDocPath = `${collections.customers}/${customerId}/${collections.carts}/${customerId}`;
        const foodLinesPath = `${cartDocPath}/${subCollections.foodLines}`;

        // Start Firestore transaction
        await runTransaction(firestore, async (transaction) => {
            const cartDocRef = doc(firestore, cartDocPath);
            const cartSnapshot = await transaction.get(cartDocRef);
            let unitPrice = details.price;
            let additionalCharges = calculateAdditionalCharges(details.productModGroups, quantities);

            // Update unit price with additional charges
            unitPrice += additionalCharges;

            // Calculate total amount
            const amount = unitPrice * details.productQuantity;

            if (!cartSnapshot.exists()) {
                // Create a new cart if it doesn't exist
                await createCart(transaction, cartDocRef, {
                    details,
                    amount,
                    orderData,
                    authData,
                    account,
                    storeData,
                });
            } else {
                // Update the existing cart
                await updateCart(transaction, cartDocRef, cartSnapshot, details, amount);
            }

            // Handle food lines
            await handleFoodLines(transaction, foodLinesPath, {
                details,
                quantities,
                unitPrice,
                inAHurry,
                isMixed,
                amount,
                remarks,
            });
        });

        return { success: true };
    } catch (error) {
        console.error('Error adding/updating cart:', error);
        return { success: false, error: error.message || error }; // Return a clearer error message
    }
};

/**
 * Calculates additional charges based on modifiers.
 *
 * @param {Array} productModGroups - The array of modifier groups for the product.
 * @param {Array} quantities - The array of quantities for each modifier group.
 * @returns {number} - The total additional charges.
 */
const calculateAdditionalCharges = (productModGroups, quantities) => {
    return productModGroups.reduce((total, group, groupIndex) => {
        return (
            total +
                group.modifiers?.reduce((sum, modifier, modifierIndex) => {
                    const quantity = quantities[groupIndex]?.[modifierIndex] || 0;
                    return sum + (modifier.price > 0 ? modifier.price * quantity : 0);
                }, 0) || 0
        );
    }, 0);
};

/**
 * Creates a new cart document in Firestore.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {Object} cartDocRef - Reference to the cart document.
 * @param {Object} details - The product details object.
 * @param {number} unitPrice - The unit price of the product.
 * @param {number} totalAmount - The total amount including tax.
 * @param {number} totalTax - The total tax amount.
 * @param {Object} orderData - Information related to the order.
 */
const createCart = async (transaction, cartDocRef, { details, amount, orderData, authData, account, storeData }) => {
    const cartData = {
        channel: cart.channel,
        count: details.productQuantity,
        createdOn: serverTimestamp(),
        customer: {
            location: {
                lat: account?.selectedAddress?.address?.geoData?.coordinates?.lat,
                long: account?.selectedAddress?.address?.geoData?.coordinates?.lng,
            },
            customerId: authData?.uid || '',
            firstName: authData?.firstName || '',
            lastName: authData?.lastName || '',
        },
        config: orderData?.companyData,
        lang: 'en',
        promiseTime: cart.promise_time,
        region: '',
        store: {
            companyId: orderData.companyId,
            storeId: orderData.storeId,
            location: {
                lat: storeData?.nearestStore?.location?.coordinates[1],
                long: storeData?.nearestStore?.location?.coordinates[0],
            },
        },
        outletCode: orderData?.outletCode,
        paymentMode: 'ONLINE',
        service: orderData.type.toUpperCase(),
        source: cart.source,
        itemTotal: amount,
    };
    transaction.set(cartDocRef, cartData);
};

/**
 * Updates an existing cart document in Firestore.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {Object} cartSnapshot - Snapshot of the existing cart document.
 * @param {Object} details - The product details object.
 * @param {number} unitPrice - The unit price of the product.
 * @param {number} amount - The amount for the new product added.
 * @param {number} totalTax - The total tax amount.
 */
const updateCart = async (transaction, cartDocRef, cartSnapshot, details, amount) => {
    const existingCartData = cartSnapshot.data();
    const newCount = existingCartData.count + details.productQuantity;
    const newItemTotal = existingCartData.itemTotal + amount;

    // Update the cart document in the transaction
    transaction.update(cartDocRef, {
        count: newCount,
        itemTotal: newItemTotal,
    });
};

/**
 * Handles food lines in the cart by either updating existing lines or adding new ones.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {string} foodLinesPath - Path to the food lines collection.
 * @param {Object} details - The product details object.
 * @param {Array} quantities - The array of quantities for each modifier group and its modifiers.
 * @param {number} totalAmount - The total amount including tax.
 * @param {number} totalTax - The total tax amount.
 * @param {number} unitPrice - The unit price of the product.

 */
const handleFoodLines = async (transaction, foodLinesPath, foodLineInfo) => {
    const { details, quantities, unitPrice, inAHurry, isMixed, amount, remarks } = foodLineInfo;
    const productDescription = createProductDescription(details, quantities);
    const simplifiedModGroups = simplifyProductModGroups(details.productModGroups, quantities, isMixed);

    const foodLineData = {
        additionalChargeComponents: '',
        discountComponents: [],
        item: simplifiedModGroups,
        quantity: details.productQuantity,
        unitPrice: unitPrice,
        amount: amount,
        productDescription,
        details: {
            id: details.id,
            name: details.name,
            price: details.price,
            desc: details.description,
            categoryId: details.categoryId,
            image: details.image,
            type: details.type,
        },
        remarks,
        inHurry: inAHurry,
    };

    const foodLinesCollectionRef = collection(firestore, foodLinesPath);
    const foodLineQuery = query(foodLinesCollectionRef, where('productDescription', '==', productDescription));
    const foodLineSnapshot = await getDocs(foodLineQuery);

    if (!foodLineSnapshot.empty) {
        // If an existing document is found, update its quantity and recalculate total amount and tax
        foodLineSnapshot.forEach((doc) => {
            const existingData = doc.data();
            const newQuantity = existingData.quantity + details.productQuantity;
            const newAmount = unitPrice * newQuantity;

            // Recalculate tax components and total tax based on new total quantity and amount

            transaction.update(doc.ref, {
                quantity: newQuantity,
                amount: newAmount,
            });
        });
    } else {
        // If no document is found, create a new food line document
        const newFoodLineDocRef = doc(collection(firestore, foodLinesPath));
        transaction.set(newFoodLineDocRef, foodLineData);
    }
};

/**
 * Fetches all food line items in the cart for a specific customer from Firestore.
 *
 * @param {string} customerId - The ID of the customer.
 * @returns {Object} - An object containing an array of food line items or an error message.
 */

export const listenToCartFoodLines = (customerId, callback) => {
    try {
        const cartDocRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);
        const foodLinesCollectionRef = collection(
            firestore,
            `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}`
        );

        // Listen to the cart document
        const cartUnsubscribe = onSnapshot(cartDocRef, (cartDocSnapshot) => {
            if (!cartDocSnapshot.exists()) {
                callback({ success: false, message: 'Cart not found for this customer.' });
                return;
            }

            // Listen to the food line items
            const foodLinesUnsubscribe = onSnapshot(foodLinesCollectionRef, (foodLineSnapshot) => {
                const foodLines = foodLineSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                // Trigger callback with updated data
                callback({
                    success: true,
                    cart: { id: cartDocSnapshot.id, ...cartDocSnapshot.data() },
                    foodLines,
                });
            });

            // Return a cleanup function to stop listening to changes
            return () => foodLinesUnsubscribe();
        });

        return cartUnsubscribe;
    } catch (error) {
        console.error('Error setting up live listeners for cart and food lines:', error);
        callback({ success: false, error: error.message || error });
    }
};
/**
 * Fetches a specific food line item in the cart for a customer from Firestore.
 *
 * @param {string} customerId - The ID of the customer.
 * @param {string} foodLineId - The ID of the food line item.
 * @returns {Object} - An object containing the food line item data or an error message.
 */
export const getFoodLine = async (foodLineId, authData) => {
    try {
        const customerId = authData?.uid; // Replace with the actual customer ID
        const foodLineRef = doc(
            firestore,
            `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${foodLineId}`
        );

        // Fetch the food line document
        const foodLineSnapshot = await getDoc(foodLineRef);

        if (!foodLineSnapshot.exists()) {
            return { success: false, message: 'Food line item not found.' };
        }

        return {
            success: true,
            foodLine: { id: foodLineSnapshot.id, ...foodLineSnapshot.data() },
        };
    } catch (error) {
        console.error('Error fetching food line item:', error);
        return { success: false, error: error.message || error };
    }
};

export const handleDeleteFoodLine = async (foodLineId, authData) => {
    const customerId = authData?.uid; // Replace with the actual customer ID
    const foodLineRef = doc(
        firestore,
        `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${foodLineId}`
    );
    const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);

    try {
        await runTransaction(firestore, async (transaction) => {
            // Get the food line data
            const foodLineSnapshot = await transaction.get(foodLineRef);
            if (!foodLineSnapshot.exists()) throw new Error('Food line not found');
            const foodLineData = foodLineSnapshot.data();
            const { unitPrice, quantity } = foodLineData;

            // Get the cart data
            const cartSnapshot = await transaction.get(cartRef);
            if (!cartSnapshot.exists()) throw new Error('Cart not found');
            const cartData = cartSnapshot.data();

            // Update cart values by subtracting food line values
            const updatedCount = cartData.count - quantity;
            const updateditemToal = cartData.itemTotal - unitPrice * quantity;

            // Delete the food line
            transaction.delete(foodLineRef);

            // Update the cart document with the adjusted values
            transaction.update(cartRef, {
                count: updatedCount,
                itemTotal: updateditemToal,
            });

            // Remove coupon if the cart becomes empty
            if (updatedCount === 0) {
                localStorage.removeItem(localStorageKeys.COUPON);
            }
        });
        return true;
    } catch (error) {
        console.error('Error deleting food line and updating cart:', error);
    }
};

export const updateCartQuantity = async (productId, newQuantity, account, operation, customerId) => {
    const foodLineRef = doc(
        firestore,
        `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${productId}`
    );
    const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);
    try {
        await runTransaction(firestore, async (transaction) => {
            // Get food line data
            const foodLineSnapshot = await transaction.get(foodLineRef);
            if (!foodLineSnapshot.exists()) throw new Error('Food line not found');
            const foodLineData = foodLineSnapshot.data();
            const unitPrice = foodLineData.unitPrice;

            // Calculate the updated amount and tax based on the new quantity
            const updatedAmount = unitPrice * newQuantity;

            // Get current cart data
            const cartSnapshot = await transaction.get(cartRef);
            if (!cartSnapshot.exists()) throw new Error('Cart not found');
            const cartData = cartSnapshot.data();

            let updatedCount, foodLineOldQuantity, foodLineOldAmount, updatedSubtotal;
            updatedCount = cartData.count - foodLineData.quantity + newQuantity;
            // Calculate updated count and subtotal for the cart
            if (operation === Operation.increment) {
                foodLineOldQuantity = newQuantity - 1;
                foodLineOldAmount = foodLineData.unitPrice * foodLineOldQuantity;
                updatedSubtotal = cartData.itemTotal - foodLineOldAmount + updatedAmount;
            } else {
                foodLineOldQuantity = newQuantity + 1;
                foodLineOldAmount = foodLineData.unitPrice * foodLineOldQuantity;
                updatedSubtotal = cartData.itemTotal - foodLineOldAmount + updatedAmount;
            }

            // Update the food line document
            if (newQuantity == 0) {
                transaction.delete(foodLineRef);
            } else {
                transaction.update(foodLineRef, {
                    quantity: newQuantity,
                    amount: updatedAmount,
                });
            }

            // Update the cart document
            transaction.update(cartRef, {
                count: updatedCount,
                itemTotal: updatedSubtotal,
            });
        });

        return { success: true };
    } catch (error) {
        console.error('Error updating cart quantity:', error);
        return { success: false, error: error.message || error };
    }
};

export const totalCalculationAndCartUpdate = async (customerId, billingInfo) => {
    const {
        tax,
        gst,
        cgst,
        sgst,
        vat,
        deliveryFee,
        discountAmount,
        usedCoins,
        earnedCoins,
        couponData,
        subTotal,
        total,
    } = billingInfo;
    const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);

    try {
        await runTransaction(firestore, async (transaction) => {
            // Get current cart data
            transaction.update(cartRef, {
                tax: tax,
                gst: gst,
                cgst: cgst,
                sgst: sgst,
                vat: vat,
                deliveryFee: deliveryFee,
                couponDiscount: discountAmount,
                earnedCoins: earnedCoins,
                couponData: couponData,
                usedCoins: usedCoins,
                subtotal: subTotal,
                total: total,
            });
        });
    } catch (error) {
        console.error('Error updating cart quantity:', error);
        return { success: false, error: error.message || error };
    }
};
