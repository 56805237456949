import { deleteDocument, executeQuery, getCollectionRef, getDocRef, updateDocument } from '@/lib/firebase/query';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '../icons/CloseIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import AddressForm from '../LocationSelector/AddressForm';
import LocationMap from '../LocationSelector/LocationMap';
const AddressBooks = ({
  onClose
}) => {
  const [addresses, setAddresses] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newAddress, setNewAddress] = useState({});
  const t = useTranslations('profile');
  const authData = useSelector(state => state.auth)?.auth;
  const getSavedLocations = useCallback(async () => {
    setIsLoading(true);
    try {
      const parentDocRef = getDocRef(collections.customers, authData.uid);
      const subCollectionRef = getCollectionRef(subCollections.addresses, parentDocRef);
      const response = await executeQuery({
        ref: subCollectionRef,
        sort: {
          field: 'createdAt',
          order: 'desc'
        },
        conditions: [{
          field: 'isSelected',
          operator: '==',
          value: true
        }],
        pageSize: 20
      });
      setAddresses(response);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    getSavedLocations();
  }, [getSavedLocations]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest('.dropdown-btn')) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  useEffect(() => {
    setNewAddress(editingAddress?.address || {});
  }, [editingAddress]);
  const handleChange = (name, value) => {
    setNewAddress({
      ...newAddress,
      [name]: value
    });
  };
  const handleDelete = useCallback(async addressId => {
    setIsLoading(true);
    try {
      const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
      await deleteDocument(path, addressId);
      getSavedLocations();
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
      setOpenDropdownId(null);
    }
  }, []);
  const prepareAddressData = data => {
    return {
      address: {
        name: data.name,
        phone: data.phone,
        houseNumber: data.houseNumber,
        apartment: data.apartment,
        directions: data.directions,
        addressType: data.addressType,
        geoData: {
          formattedAddress: data?.geoData?.formattedAddress,
          coordinates: {
            ...data?.geoData?.coordinates
          }
        }
      },
      sOrder: new Date().toISOString()
    };
  };
  const handleUpdate = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedData = prepareAddressData(newAddress);
      const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
      await updateDocument(path, editingAddress.id, updatedData);
      setEditingAddress(null);
      getSavedLocations();
    } catch (error) {
      console.error('error', error);
    }
  }, [editingAddress, newAddress]);
  const toggleDropdown = useCallback(addressId => {
    setOpenDropdownId(prev => prev === addressId ? null : addressId);
  }, []);
  const handleEditClick = useCallback(address => {
    setEditingAddress(address);
    setOpenDropdownId(null);
  }, []);
  const formatAddress = useCallback(address => {
    const parts = [];
    if (address.houseNumber) parts.push(address.houseNumber);
    if (address.apartment) parts.push(address.apartment);
    if (address.geoData?.formattedAddress) parts.push(address.geoData.formattedAddress);
    if (address.directions) parts.push(address.directions);
    return parts.join(', ');
  }, []);
  const renderAddressItem = useCallback(address => <div key={address.id} className="item-locations" style={{
    cursor: 'pointer'
  }}>
                <div className="item-header">
                    <div className="dropdown-block">
                        <span>{address.address.addressType}</span>
                        <div className="dropdown-btn">
                            <button className="menu-dot-icon" onClick={e => {
            e.stopPropagation();
            toggleDropdown(address.id);
          }}>
                                <ThreeDotsIcon />
                            </button>
                            {openDropdownId === address.id && <div className="dropdown">
                                    <ul>
                                        <li>
                                            <button onClick={e => {
                  e.preventDefault();
                  handleEditClick(address);
                }}>
                                                Edit
                                            </button>
                                        </li>
                                        <li className="delete-btn">
                                            <button onClick={e => {
                  e.stopPropagation();
                  handleDelete(address.id);
                }}>
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>}
                        </div>
                    </div>
                    <h6>{address.address.houseNumber}</h6>
                </div>
                <p>{formatAddress(address.address)}</p>
            </div>, [openDropdownId, toggleDropdown, handleEditClick, handleDelete, formatAddress]);
  if (editingAddress) {
    return <>
                <button className="overlay" onClick={onClose}></button>
                <section className="drawer address-book">
                    <div className="sheetHeader">
                        <h4>
                            Edit Address <small>Update your delivery address</small>
                        </h4>
                        <button className="closeBtn" onClick={() => setEditingAddress(null)}>
                            Back
                        </button>
                    </div>
                    <div className="location-wrap">
                        <LocationMap onLocationChange={handleUpdate} coordinates={editingAddress?.address?.geoData?.coordinates} />
                        <AddressForm currentAddress={newAddress} handleFieldChange={handleChange} />
                        <button className="submint-btn" onClick={handleUpdate}>
                            {t('confirmLocation')}
                        </button>
                    </div>
                </section>
            </>;
  }
  return <>
            <button className="overlay" onClick={onClose}></button>
            <section className="drawer address-book">
                <div className="sheetHeader">
                    <h4>
                        Address Book <small>Select or manage your delivery addresses</small>
                    </h4>
                    <button className="closeBtn" onClick={onClose}>
                        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.jsx" />
                    </button>
                </div>
                <div className="location-wrap">
                    <div className="saved-locations">
                        <h4>Saved Address</h4>
                        <AddressListStatus isLoading={isLoading} addresses={addresses} renderAddressItem={renderAddressItem} data-sentry-element="AddressListStatus" data-sentry-source-file="index.jsx" />
                        <button className="logoutProfile">Add new address</button>
                    </div>
                </div>
            </section>
        </>;
};
export default AddressBooks;
AddressBooks.propTypes = {
  onClose: PropTypes.func
};
const AddressListStatus = ({
  isLoading,
  addresses,
  renderAddressItem
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (addresses.length === 0) {
    return <div>No saved addresses found.</div>;
  }
  return addresses.map(renderAddressItem);
};
AddressListStatus.propTypes = {
  isLoading: PropTypes.bool,
  addresses: PropTypes.array,
  renderAddressItem: PropTypes.func
};