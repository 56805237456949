import AddressBooks from '@/components/AddressBook';
import { CommonModal } from '@/components/Modal/CommonModal';
import { Toggle } from '@/components/Toggle/Toggle';
import LocaleSwitch, { localeSwitchType } from '@/components/header/LocaleSwitch';
import AddressBook from '@/components/icons/AddressBook';
import CancellationPolicy from '@/components/icons/CancellationPolicy';
import ChangeLanguage from '@/components/icons/ChangeLanguage';
import ChevronRight from '@/components/icons/ChevronRight';
import Choosetheme from '@/components/icons/Choosetheme';
import CoinIcon from '@/components/icons/CoinIcon';
import Favourite from '@/components/icons/Favourite';
import Orders from '@/components/icons/Orders';
import PrivacyPolicy from '@/components/icons/PrivacyPolicy';
import PushNotification from '@/components/icons/PushNotification';
import SavedPayments from '@/components/icons/SavedPayments';
import TermsConditions from '@/components/icons/TermsConditions';
import ValueCard from '@/components/icons/ValueCard';
import { localStorageKeys } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useLocalStorage from '@/hooks/useLocalStorage';
import { capitalizeFirstLetter, getUserFirstAndLastLetter } from '@/lib/helpers/common';
import { wrapper } from '@/redux/store';
import { updateUser } from '@/service/user.service';
import loadMessages from '@/utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Myorders from './myOrders';
import ProfileEdit from './profileEdit';
import ProfileHeader from './profileHeader';
const screens = {
  PROFILE_HOME: 'profileHome',
  ADDRESS_BOOK: 'addressBook',
  EDIT_ADDRESS: 'editAddress'
};
const Profile = ({
  setShowProfile,
  showProfile
}) => {
  const router = useRouter();
  const {
    logout
  } = useAuth();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const t = useTranslations('profile'); // Translation hook
  const [showMyOrders, setShowMyOrders] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const profileData = useSelector(store => store.auth)?.auth;
  const orderData = useSelector(store => store.order).order;
  const [currentScreen, setCurrentScreen] = useState(screens.PROFILE_HOME);
  const [isPush, setIsPush] = useState(profileData?.isPush);
  const {
    firstName,
    lastName,
    phoneNumber,
    phoneCode,
    loyalty
  } = profileData || {};
  const loyaltyPoint = loyalty?.[orderData?.companyData?.country?.countryCode] || 0;
  useEffect(() => {
    // to be moved to a common drawer component once its implemented
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  const updateScreen = screen => {
    setCurrentScreen(screen);
  };
  if (showMyOrders) {
    return <Myorders setShowProfile={setShowProfile} setShowMyOrders={setShowMyOrders} />;
  }
  if (currentScreen === screens.ADDRESS_BOOK) {
    return <AddressBooks setCurrentScreen={setCurrentScreen} onClose={() => updateScreen(screens.PROFILE_HOME)} />;
  }
  const handleToggled = () => {
    setIsPush(!isPush);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsPush(profileData?.isPush);
    setOpenModal(false);
  };
  const handlePushSubmit = async selected => {
    setIsPush(selected);
    await updateUser({
      isPush: selected
    }).then(res => res.code === 200 && toast.success(t('push_notification')));
    setOpenModal(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      actions.clearAllExcept([`${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.SELECTED_COUNTRY}`, `${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.SELECTED_ORDER_TYPE}`]);
      router.push('/auth/signin');
    } catch (error) {
      console.error(error);
    }
  };
  return <>
            <div className="overlay"></div>
            <section className="drawer">
                <ProfileHeader setShowProfile={setShowProfile} showProfile={showProfile} title={t('profile_title')}
      // subtitle={t('profile_subtitle')}
      data-sentry-element="ProfileHeader" data-sentry-source-file="index.jsx" />
                <div>
                    <div className="profileDetails-wrap">
                        <div className="profileDetails">
                            <div className="profileDetails-header">
                                <div className="avatar">
                                    <div className="avatar_icon">
                                        <span>{getUserFirstAndLastLetter(firstName, lastName)}</span>
                                    </div>
                                </div>
                                <h4>
                                    {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
                                    <small>
                                        +{phoneCode}-{phoneNumber}
                                    </small>
                                </h4>
                            </div>
                            <div>
                                <button className="editBtn" onClick={() => setEditEnabled(prev => !prev)}>
                                    {/* {editEnabled ? t('cancel') : t('edit')} */}
                                    {editEnabled ? '' : t('edit')}
                                </button>
                            </div>
                        </div>

                        <ProfileEdit profileData={profileData} setEditEnabled={setEditEnabled} editEnabled={editEnabled} data-sentry-element="ProfileEdit" data-sentry-source-file="index.jsx" />
                    </div>
                    <button className="bite-btn profile-bite-btn">
                        <CoinIcon data-sentry-element="CoinIcon" data-sentry-source-file="index.jsx" />
                        <div>
                            {t('you_have')}{' '}
                            <span>
                                {loyaltyPoint} {t('bitecoins')}.
                            </span>
                        </div>
                    </button>
                </div>
                {/* orders section */}
                <div className="edit_Header">
                    <h4>{t('orders_title')}</h4>
                </div>
                <div className="settingsSection ">
                    <ul>
                        <li>
                            <Link href="" onClick={() => setShowMyOrders(!showMyOrders)} data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <Orders data-sentry-element="Orders" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('orders_myOrders')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <ValueCard data-sentry-element="ValueCard" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('orders_valueCard')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <Favourite data-sentry-element="Favourite" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('orders_favouriteFood')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" onClick={() => setCurrentScreen(screens.ADDRESS_BOOK)} data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <AddressBook data-sentry-element="AddressBook" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('orders_addressBook')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <SavedPayments data-sentry-element="SavedPayments" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('orders_savedPayments')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* settings section */}
                <div className="edit_Header">
                    <h4>{t('settings_title')}</h4>
                </div>
                <div className="settingsSection">
                    <ul>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <ChangeLanguage data-sentry-element="ChangeLanguage" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_changeLanguage')}</span>
                                </div>

                                {/* <LanguageSelector
                                    selected={selected}
                                    toggleSelected={() => {
                                        setSelected(!selected);
                                    }}
                                 /> */}
                                <LocaleSwitch type={localeSwitchType.TOGGLE} data-sentry-element="LocaleSwitch" data-sentry-source-file="index.jsx" />
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <PushNotification data-sentry-element="PushNotification" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_pushNotification')}</span>
                                </div>
                                <Toggle selected={isPush} toggleSelected={handleToggled} data-sentry-element="Toggle" data-sentry-source-file="index.jsx" />
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <Choosetheme data-sentry-element="Choosetheme" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_chooseTheme')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <TermsConditions data-sentry-element="TermsConditions" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_termsConditions')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <PrivacyPolicy data-sentry-element="PrivacyPolicy" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_privacyPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="icon">
                                        <CancellationPolicy data-sentry-element="CancellationPolicy" data-sentry-source-file="index.jsx" />
                                    </span>
                                    <span className="menuText">{t('settings_cancellationPolicy')}</span>
                                </div>
                                <span className="icon chevron">
                                    <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link href="" data-sentry-element="Link" data-sentry-source-file="index.jsx">
                                <div>
                                    <span className="menuText deleteAccount">{t('settings_deleteAccount')}</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <button className="logoutProfile" onClick={handleLogout}>
                    {t('logout')}
                </button>
            </section>
            <CommonModal title={t('push_notification_modal_title')} show={openModal} onCancel={handleCloseModal} onConfirm={() => handlePushSubmit(isPush)} confirmText={t('Confirm')} cancelText={t('Cancel')} isOpen={openModal} closeIcon={true} data-sentry-element="CommonModal" data-sentry-source-file="index.jsx" />
        </>;
};
export const getStaticProps = wrapper.getStaticProps(() => async ({
  locale
}) => {
  return {
    props: {
      locale,
      messages: await loadMessages(locale)
    }
  };
});
Profile.propTypes = {
  setShowProfile: PropTypes.func.isRequired,
  showProfile: PropTypes.bool.isRequired
};
export default Profile;