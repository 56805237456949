import accountReducer from '@/redux/slices/account';
import authReducer from '@/redux/slices/auth';
import cartReducer from '@/redux/slices/cart';
import commonReducer from '@/redux/slices/common';
import orderReducer from '@/redux/slices/order';
import storeReducer from '@/redux/slices/stores';
import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch, useSelector, useStore } from 'react-redux';

export const makeStore = () => {
    return configureStore({
        reducer: {
            common: commonReducer,
            account: accountReducer,
            order: orderReducer,
            auth: authReducer,
            cart: cartReducer,
            store: storeReducer,
        },
    });
};

export const wrapper = createWrapper(makeStore, { debug: false });

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes();
export const useAppSelector = useSelector.withTypes();
export const useAppStore = useStore.withTypes();
