import { firestore } from '@/configs/firebase.config';
import { callCloudFunction } from '@/lib/firebase/functions';
import { generateHomeData } from '@/utils';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import functions from '@/utils/constants/fb.functions';

import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';

/**
 * Get the home data for a given country and location.
 * If the location is not provided, the default company for the given country is used.
 * @param {string} country - The country to get the data for.
 * @param {string} [location] - The location to get the data for.
 * @returns {Promise<QueryDocumentSnapshot[]>} - The snapshot of the documents from the home subcollection.
 * @throws {Error} - If there is an error getting the data.
 */
export const getHomeData = async (country, orderDetails) => {
    if (!country) return;
    try {
        // orderDetails = orderDetails?.companyId || {
        //     type: 'DELIVERY',
        //     companyId: 30,
        //     storeId: 20,
        //     countryCode: 'US',
        //     order: {
        //         type: 'TAKE_AWAY',
        //     },
        // };
        if (!country) return;
        let querySnapshot;
        let companyId;
        let companyRef = collection(firestore, collections.companies);
        if (!orderDetails?.companyId) {
            const countryRef = doc(firestore, collections.countries, country);
            const docSnap = await getDoc(countryRef);
            if (docSnap.exists()) {
                companyId = String(docSnap.data()?.defaultCompanyId);
                querySnapshot = await getDocs(
                    collection(companyRef, String(docSnap.data()?.defaultCompanyId), subCollections.home)
                );
            }
        } else {
            const path = collection(
                firestore,
                collections.companies,
                String(orderDetails.companyId),
                subCollections.stores,
                String(orderDetails.storeId),
                subCollections.home
            );
            companyId = String(orderDetails?.companyId);
            querySnapshot = await getDocs(path);
        }
        const companyDetails = await getDoc(doc(firestore, collections.companies, companyId));
        return {
            home: generateHomeData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
            ),
            companyDetails: companyDetails.data(),
        };
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

/**
 * Gets the products of a given store.
 *
 * @param {string} companyId The id of the company.
 * @param {string} storeId The id of the store.
 * @param {string} collectionName The name of the collection.
 * @param {string} subCollectionName The name of the subcollection.
 * @param {string} innerCollectionName The name of the inner collection.
 * @returns {Promise<Array<{id: string, [key: string]: any}>>} An array of objects with the id and data of each product.
 * @throws {Error} If there is an error getting the products.
 */
export const getStoreProducts = async (companyId, storeId, collectionName, subCollectionName, innerCollectionName) => {
    try {
        const productRef = collection(
            doc(firestore, `${collectionName}/${companyId}/${subCollectionName}/${storeId}`),
            innerCollectionName
        );
        const querySnapshot = await getDocs(productRef);
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

export const getNearByStores = async (request) => {
    try {
        return await callCloudFunction(functions.nearByStores, request);
    } catch (error) {
        console.error('error in getNearByStores: ', error);
        throw error;
    }
};

export const getNearestStore = async (request) => {
    try {
        return await callCloudFunction(functions.getNearestStore, request);
    } catch (error) {
        console.error('error in getNearestStore: ', error);
        throw error;
    }
};

export const getCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.countries);
        const sortedQuery = query(countriesRef, orderBy('sOrder', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        // Map the documents into an array
        const countries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        return countries;
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};
export const getMasterCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.masterCountries);
        const sortedQuery = query(countriesRef, orderBy('name.en', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        const masterCountries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return masterCountries;
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};

// firebase query to read all data from home collection if the
