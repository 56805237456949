// src/hooks/useCartFoodLines.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listenToCartFoodLines } from '@/service/cart';
import { setFoodLines, setCartData } from '@/redux/slices/cart';
const useCartFoodLines = customerId => {
  const dispatch = useDispatch();
  const foodLines = useSelector(state => state.cart.foodLines);
  const cart = useSelector(state => state.cart.cart);
  useEffect(() => {
    const unsubscribe = listenToCartFoodLines(customerId, data => {
      if (data.success) {
        dispatch(setFoodLines(data?.foodLines));
        dispatch(setCartData(data?.cart));
      } else {
        console.error(data.message || data.error);
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [customerId]);
  return {
    foodLines,
    cart
  };
};
export default useCartFoodLines;