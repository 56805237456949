import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth: {},
};

const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        updateAuthData(state, action) {
            return {
                auth: {
                    ...state.auth,
                    ...action.payload,
                },
            };
        },
        setAuthData(state, action) {
            state.auth = action.payload;
        },
    },
});

export const { updateAuthData, setAuthData } = authSlice.actions;
export default authSlice.reducer;
