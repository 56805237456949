import { firestore } from '@/configs/firebase.config';
import { subCollections } from '@/utils/constants/fb.collections';
import { collection, doc, getDocs, onSnapshot, orderBy, query, limit, where, startAfter } from 'firebase/firestore';

export const getOrderTrackingHistory = async (orderNumber, setTrackingData) => {
    const ordersCollection = subCollections.orders;
    const orderHistoryCollection = subCollections.orderHistory;

    try {
        const ordersQuery = query(collection(firestore, ordersCollection), where('orderNumber', '==', orderNumber));

        const querySnapshot = await getDocs(ordersQuery);
        if (!querySnapshot.empty) {
            const orderId = querySnapshot.docs[0].id;
            const orderRef = doc(firestore, `${ordersCollection}/${orderId}`);
            const orderHistoryRef = collection(orderRef, orderHistoryCollection);
            const orderHistoryQuery = query(orderHistoryRef, orderBy('createdAt', 'asc'));

            const unsubscribe = onSnapshot(
                orderHistoryQuery,
                (querySnapshot) => {
                    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                    setTrackingData(data);
                },
                (error) => {
                    console.error('Error listening for changes:', error);
                }
            );

            return { destroy: unsubscribe };
        }
    } catch (error) {
        console.error('error in getOrderTrackingHistory: ', error);
        throw error;
    }
};

export const getOrderList = async ({ customerId, limitValue, lastVisibleDoc = {} }) => {
    const ordersSubCollection = subCollections.orders;

    try {
        const ordersQuery = query(
            collection(firestore, ordersSubCollection),
            where('customerId', '==', customerId),
            orderBy('createdAt', 'desc'),
            limit(limitValue),
            startAfter(lastVisibleDoc)
        );

        const ordersSnapshot = await getDocs(ordersQuery);

        const data = ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const lastVisible = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];

        return { data, lastVisible };
    } catch (error) {
        console.error('error in getOrderList: ', error);
        throw error;
    }
};
