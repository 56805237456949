import { localStorageKeys, modalTypeKey, ORDER_TYPES } from '@/configs/constants';
import useDeviceLocation from '@/hooks/useDeviceLocation';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import Profile from '@/pages/profile';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ChevronDown from '../icons/ChevronDown';
import Location from '../icons/Location';
import User from '../icons/User';
import CartHeader from './CartHeader';
import { Greetings } from './Greetings';
const Header = () => {
  const t = useTranslations('Home');
  const router = useRouter();
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    getLocation
  } = useDeviceLocation();
  const orderData = useSelector(store => store.order)?.order;
  const accountData = useSelector(store => store.account)?.account;
  const userData = useSelector(store => store.auth)?.auth;
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const orderType = actions.getNestedValue(localStorageKeys.SELECTED_ORDER_TYPE);
  const [showProfile, setShowProfile] = useState(false);
  const handleGetLocation = async () => {
    try {
      await getLocation();
    } catch (error) {
      console.error('Failed to get location:', error.message);
    }
  };
  const getAddress = () => {
    const parts = [];
    if (orderData?.address?.addressType) {
      parts.push(orderData.address.addressType);
    }
    if (orderData?.address?.apartment) {
      parts.push(orderData.address.apartment);
    }
    if (orderData?.address?.formatted) {
      parts.push(orderData.address.formatted);
    } else if (orderData?.address?.geoData?.formattedAddress) {
      parts.push(orderData.address.geoData.formattedAddress);
    } else if (accountData?.deviceLocation?.address) {
      return accountData.deviceLocation.address;
    }
    return parts.join(', ') || '';
  };
  const address = getAddress();
  if (showProfile) {
    return <Profile setShowProfile={setShowProfile} showProfile={showProfile} />;
  }
  const handleNavigations = action => {
    switch (action) {
      case 'HOME':
        router.push('/home');
        break;
      case 'GET_STARTED':
        router.push('/auth/signin');
        break;
      case 'GLOBAL_ORDER':
        addMultipleParams({
          [modalTypeKey]: 'locationSelector',
          mode: 'globalOrder'
        });
        break;
      case 'FIND_LOCATION':
        addMultipleParams({
          [modalTypeKey]: 'locationSelector',
          mode: 'searchLocation'
        });
        break;
      default:
        break;
    }
  };
  const getLocationLabel = () => {
    if (orderType === ORDER_TYPES.DELIVERY) {
      return t('delivery_to_label');
    }
    if (orderType === ORDER_TYPES.TAKE_AWAY) {
      return t('collect_from_label');
    }
    return null;
  };
  return <header data-sentry-component="Header" data-sentry-source-file="header.jsx">
            <div className="top_controls_wrapper">
                <div className="container">
                    <div className="mode_switcher">{/* <LocaleSwitch type={localeSwitchType.SELECT} /> */}</div>
                    <div className="location_update">
                        <Location data-sentry-element="Location" data-sentry-source-file="header.jsx" />
                        {address ? <button className="current-location-txt" onClick={handleGetLocation}>
                                {getLocationLabel()} <span title={address}>{address}</span>
                            </button> : <button className="current-location-txt" onClick={handleGetLocation}>
                                {getLocationLabel()} <a href="##">{t('select_location')}</a>
                            </button>}
                        {address && <button className="change-btn" onClick={() => handleNavigations('FIND_LOCATION')}>
                                {t('change_label')}
                            </button>}
                    </div>
                </div>
            </div>
            <div className="header_wrapper">
                <div className="container">
                    <div className="logo_wrapper">
                        <Link href="/home" data-sentry-element="Link" data-sentry-source-file="header.jsx">
                            <img src="/images/chicking_logo.png" alt="chicking_logo" />
                        </Link>
                    </div>
                    <div className="authControls">
                        <CartHeader data-sentry-element="CartHeader" data-sentry-source-file="header.jsx" />

                        <button className="primary_btn" onClick={() => handleNavigations('GLOBAL_ORDER')}>
                            {t('global_order_label')}
                        </button>
                        {!userData?.isUserLoggedIn && <button className="default_btn" onClick={() => handleNavigations('GET_STARTED')}>
                                {t('lets_get_started_label')}
                            </button>}
                        {userData?.isUserLoggedIn && <Link href="" onClick={() => setShowProfile(true)}>
                                <div className="userDatawrap">
                                    <div className="userIcon">
                                        <div className="avatar">
                                            <div className="avatar_icon">
                                                <User />
                                            </div>
                                        </div>
                                    </div>
                                    <h4>
                                        <small>
                                            {t('hello')} {userData?.firstName},{' '}
                                        </small>
                                        <Greetings />
                                    </h4>
                                    <span className="icon chevron">
                                        <ChevronDown />
                                    </span>
                                </div>
                            </Link>}
                    </div>
                </div>
            </div>
        </header>;
};
export default Header;