import AuthGuard from '@/components/Layout/AuthGuard';
import MainLayout from '@/components/Layout/MainLayout';
import Loader from '@/components/loader';
import ModalWrapper from '@/components/ModalWrapper';
import useRouteChange from '@/hooks/useRouteChange';
import client from '@/lib/graphql/apolloClient';
import { wrapper } from '@/redux/store';
import '@/styles/_main.scss';
import { ApolloProvider } from '@apollo/client';
import { NextIntlClientProvider } from 'next-intl';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../components/PhoneNumberSelect/phoneNumberSelect.scss';
export default function App({
  Component,
  ...rest
}) {
  const {
    props,
    store
  } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  useRouteChange(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <div className="min-h-screen flex items-center justify-center">
                <Loader />
            </div>;
  }
  const withProviders = children => <NextIntlClientProvider messages={props.pageProps.messages} locale={router.locale} now={new Date()} onError={err => {
    console.error('Intl Error:', err);
  }} data-sentry-element="NextIntlClientProvider" data-sentry-component="withProviders" data-sentry-source-file="_app.jsx">
            <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.jsx">
                <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.jsx">
                    <MainLayout data-sentry-element="MainLayout" data-sentry-source-file="_app.jsx">{children}</MainLayout>
                </Provider>
            </ApolloProvider>
        </NextIntlClientProvider>;
  const pageTitle = 'Chicking';
  if (Component.requireAuth) {
    return withProviders(<>
                <Head>
                    <title>{pageTitle}</title>
                </Head>
                <AuthGuard>
                    <PageWrapper>
                        <Component {...props.pageProps} />
                    </PageWrapper>
                </AuthGuard>
            </>);
  }
  return withProviders(<>
            <Head>
                <title>{pageTitle}</title>
            </Head>
            <PageWrapper>
                <Component {...props.pageProps} />
            </PageWrapper>
        </>);
}
App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};
function PageWrapper({
  children
}) {
  return <>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnHover data-sentry-element="ToastContainer" data-sentry-source-file="_app.jsx" />
            <Loader data-sentry-element="Loader" data-sentry-source-file="_app.jsx" />
            <ModalWrapper data-sentry-element="ModalWrapper" data-sentry-source-file="_app.jsx" />
            {children}
        </>;
}
PageWrapper.propTypes = {
  children: PropTypes.node.isRequired
};