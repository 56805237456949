import PropTypes from 'prop-types';
import React from 'react';
import Tick from '../icons/Tick';
export const Toggle = ({
  selected,
  toggleSelected
}) => <button className="toggle-container" onClick={() => toggleSelected(!selected)} onKeyDown={e => {
  if (e.key === 'Enter' || e.key === ' ') {
    toggleSelected(!selected);
  }
}} data-sentry-component="Toggle" data-sentry-source-file="Toggle.jsx">
        <div className={`dialog-button ${selected ? '' : 'disabled'}`}>
            <Tick data-sentry-element="Tick" data-sentry-source-file="Toggle.jsx" />
        </div>
    </button>;
Toggle.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired
};