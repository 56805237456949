import { useRouter } from 'next/router';
import { useEffect } from 'react';
const useRouteChange = callback => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = url => {
      if (callback) {
        callback(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, callback]);
};
export default useRouteChange;